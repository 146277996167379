<script>

import axios from "axios";
import DatasheetFormModal from "@/entrypoints/admin/datasheets/DatasheetFormModal.vue";
export default {
  name: "Datasheets",
  components:{ DatasheetFormModal },
  data(){
    return{
      items: [],
      kind:'datasheet',
      current_ds:{name:''}
    }
  },
  methods:{
    handleNewProduct(){
      this.current_ds = {name:''}
      this.$refs.productFormDialog.showModal()
    },

    loadItems(){
      axios.get('/admin/datasheets').then(response =>{
        this.items = response.data.items
      })
    },
    btnEditItem(a){
      this.current_ds = a
      this.$refs.productFormDialog.showModal()

    },
    onDialogUpdated(kind){
      this.kind = kind
      this.loadItems()
    }

  }
  ,
  mounted() {
    this.loadItems()
  }
}
</script>

<template>

  <div class="nav d-flex">
    <div>
      Zobrazit: <select v-model="kind">
      <option value="datasheet">datasheety</option>
      <option value="drawing">výkresy</option>
      <option value="manual">manuály</option>
    </select>
    </div>

    <div class="ml-auto">
        <button class="btn btn-outline-success" @click="handleNewProduct">Nový dokument</button>
    </div>
  </div>
  <DatasheetFormModal ref="productFormDialog" :ds="current_ds" @updated="onDialogUpdated"></DatasheetFormModal>



  <table class="table">
    <thead>
    <tr>
      <td>Nazev</td>
      <td>  Typ    </td>
      <td>      </td>
      <td colspan="2"></td>
    </tr>
    </thead>
    <tbody>
      <tr v-for="item in items[kind]">
        <td>{{item.name}}</td>
        <td>{{item.kind_name}}</td>
        <td><a :href="item.file_url">{{item.filename}}</a></td>
        <td><a href="#" @click.prevent="btnEditItem(item)">upravit</a></td>
        <td>smazat</td>
      </tr>
    </tbody>
  </table>


</template>

<style scoped>

</style>