<template>
  <div class="reg-box">
    <h2 class="h6 m-0">{{$t('uvod-registrace')}}</h2>
    <div class="form-group">
      <div class="text-right">
        <a class="small" href="#" @click.prevent="$parent.box=''">{{$t('prihlas-se')}}</a>
      </div>
      <div class="form-group ">
        <label for="inputName" class="sra-only">{{$t('reg.name')}}</label>
        <input
          type="text"
          v-model.trim="user.name"
          @input="delayTouch(user.name)"
          class="form-control form-control-sm"
          id="inputName"
          :placeholder="$t('reg.name')"
        />

      </div>

      <div class="form-group ">
        <label for="inputEmail" class="sra-only">{{$t('reg.email')}}</label>
        <input
          type="email"
          v-model.trim="user.email"
          @input="delayTouch(user.email)"
          class="form-control form-control-sm"
          id="inputEmail"
          :placeholder="$t('reg.email')"
        />
      </div>

      <div class="form-group ">
        <label for="inputPassword" class="sra-only">{{$t('reg.heslo')}}</label>
        <input
          type="password"
          v-model.trim="user.password"
          class="form-control form-control-sm"
          id="inputPassword"
          :placeholder="$t('reg.heslo')"
        />
      </div>

      <div class="form-group ">
        <label for="inputCompany" class="sra-only">{{$t('reg.companyname')}}</label>
         <input
            type="text"
            v-model.trim="company"
            class="form-control form-control-sm"
            id="inputCompany"
            :placeholder="$t('reg.companyname')"
          />
      </div>





      <div class="form-group ">
        <label for="inputCat" class="">{{ $t('reg.company.category') }}</label>
        <select v-model="category" class="form-control">
          <option value="Manufacturer">{{$t('category.Manufacturer')}}</option>
          <option value="Wholesale">{{$t('category.Wholesale')}}</option>
          <option value="Manufacturer-of-unit">{{$t('category.Manufacturer-of-unit')}}</option>
          <option value="Machine-user">{{$t('category.Machine-user')}}</option>
        </select>
      </div>





      <div class="form-group ">
        <label for="inputCountry" class="sra-only">{{$t('reg.stat')}} ...</label>

        <country-select v-model="country" :country="country" class="form-control countrysel"/>

        <small class="form-text text-danger mt-0">&nbsp;<br></small>
      </div>

      <div class="form-group ">
        <label for="inputAddress" class="sra-only">{{$t('reg.adresa')}}</label>
        <input
          type="text"
          v-model.trim="user.address"

          class="form-control form-control-sm"
          id="inputAddress"
          :placeholder="$t('reg.adresa')"
        />
      </div>

      <div class="form-group ">
        <label for="inputPhone" class="sra-only">{{$t('reg.telefon')}}</label>
        <input
          type="text"
          v-model.trim="user.tel"

          class="form-control form-control-sm"
          id="inputPhone"
          :placeholder="$t('reg.telefon')"
        />

      </div>

      <div class="form-check">
        <input
          type="checkbox"
          v-model.trim="user.agree"
          class="form-check-input"
          id="exampleCheck1"
        />
        <label
          class="small form-check-label"
          for="exampleCheck1"
        >{{ $t('reg.agree-principles')}}</label>

      </div>



      <button  @click="registruj"  class="btn btn-sm btn-secondary w-100 my-2"   >{{ $t('reg.registrovat')}}</button>

      <div class="pt-2" v-if="msg.length > 0 || errors!=null">
        <i class="fas fa-info-circle mr-2"></i>
        {{msg}}
        <div v-if="errors!=null">
          <p :key="k" v-for="(e,k) in errors">{{k}} {{e}}</p>
        </div>
      </div>
    </div>



  </div>
</template>

<script>
//const qs = require("qs");

import  axios from 'axios'
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, email, sameAs } from "@vuelidate/validators";


import {translator} from "@/lib/translator";


const touchMap = new WeakMap();
//import { ComboBoxPlugin } from "@syncfusion/ej2-vue-dropdowns";
//import Vue from "vue";
import { Query } from "@syncfusion/ej2-data";
//Vue.use(ComboBoxPlugin);
//import { DropDownListPlugin } from '@syncfusion/ej2-vue-dropdowns';
//Vue.use( DropDownListPlugin);

//import vueCountryRegionSelect from 'vue-country-region-select'
//Vue.use(vueCountryRegionSelect)

export default {
  setup () {
       return {
         //$v: useVuelidate()

      }
  },
  name: "Registrace",
  components: {  },
  data() {
    return {
      invalid: false,
      region: '',
      user:{},

      email: "",
      password: "",
      country: "",
      address: "",
      company:'',
      tel: "",
      name: "",
      agree: false,
      msg: "",
      errors: null,
      company_id:'',
      branch_id: null,


      categoryData:[],
      branchesData:[],
      branch:{},
      category:null,
      data: [],
      fields: {text: 'name', value:'id'},
      height: '330px',
      waterMark: 'Select a country',


      query :  new Query().from('companies').select(['name', 'id']).take(6),

    };
  },
  mounted(){
    console.log("mounted reg");

  },
  validations: {
    email: {
      required,
      email
      // isUnique(value) {
      //     // pro validaci musi byt platny email format
      //     if (!this.user.reg.mail.email) return true

      //     return new Promise((resolve, reject) => {
      //         axios.get("/profil/check-email", {params:{email: value }}).then(response => {
      //             if (response.data.unique == 1){
      //                 //console.log('povoleni')
      //                 resolve(true)
      //             }else{
      //                 reject(false)
      //             }

      //         }).catch((error) => {
      //             reject(false)
      //         });
      //     })
      // }
    },
    password: {
      required,
      minLength: minLength(6)
    },
    // repeatPassword: {
    //   required,
    //   sameAsPassword: sameAs("password")
    // },
    country: {
      required
    },
    address: {
      required
    },
    name: {
      required
    },
    phone: {
      required
    },
    agree: {
      checked: sameAs(() => true)
    },
    company:{

    },
    register: [
      "email",
      "name",
      "password",
      "agree",
      "country",
      "address",
      "phone",
      "agree",
            "company"
    ]
  },
  methods: {
    delayTouch(obj){

    },

    $t: translator,

    onFilteringCompany: function(e) {
      //console.log('filtering, ', e.text)
      var t= e.text
      this.company = e.text
      axios.get(   `/companies/index`, {params: {q: e.text}}).then(result => {
        //console.log('reg', result.status, result.data)
          if (result.status == 200) {
             // frame the query based on search string with filter type.
            let data = result.data.companies
            //console.log('data,', data)
            e.updateData(data)
            e.text = t
          }
      })
    },

    onCompanyChange(e){
      //console.log("Company ch", e.itemData)
      this.branch_id = null
      var id = parseInt(e.itemData.id)
      this.company = e.itemData.name
      if (e.itemData.country) this.country = e.itemData.country.toUpperCase(); else this.country=''
      this.address = e.itemData.address
      if ( !isNaN(id) && id>0){
        if (e.itemData.category_id){
          this.category = e.itemData.category_id
        }
        this.reloadBranches(id)
      }
    },

    onCompanySelect(e){
      console.log("CE se", e.itemData.id)
    },

    onCategorySelect(){
      //console.log('cat sel', this.category)
    },

    onBranchSelect(e){
      console.log("branch sel ", e.itemData.id)
      this.branch_id = e.itemData.id
    },

    reloadBranches(id){

      axios.get(process.env.VUE_APP_API_ROOT_URL + "/accounts",
          {params: {company_id: id }}
      ).then(result => {
        //console.log('reg', result.status, result.data)
        if (result.status == 200) {
          this.branchesData = result.data
        }
      })

    },


    registruj() {
      const invalid=false

      if (!invalid) {

        const data = {
          user: this.user,
          company_name: this.company,
          category: this.category
        }

        axios.post("/reg-bee",  data)

          .then(result => {
            //console.log('reg', result.status, result.data)
            if (result.status == 200 && result.data.id > 0) {
              this.msg = "Registrace proběhla v pořádku.";


            } else {
              this.msg = "Chyba";
              this.errors = result.data.errors;
            }
          })
          .catch(err => {
            console.error("chyba",err.response.status,err.response.data.errors);

           this.errors = err.response.data.errors;


          });
      }
    }
  }
};
</script>
<style>
/*

// @import "../../node_modules/@syncfusion/ej2-base/styles/bootstrap4.css";
// @import "../../node_modules/@syncfusion/ej2-inputs/styles/bootstrap4.css";
// @import "../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/bootstrap4.css";
*/
.form-control.countrysel{
  color: #6c757d;
  font-size:90%;
  height:32px;
  padding:4px;
  font-size:1em

}

.reg-box{
  width:300px;
  margin:auto
}

</style>
