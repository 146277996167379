<template>
  <vue-final-modal v-model="showM" classes="modal-container" content-class="modal-content-f w800">
    <div class="modal-header">
      <b v-if="user.id>0">Detail usera</b>
      <b v-else>{{$t('team.add-member')}}</b>



      <button type="button" class="close" @click.prevent="hideModal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>

    </div>

    <div class="modal__content">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Jméno</label>
            <input type="text" v-model="user.name" class="form-control" />
          </div>
        </div>


        <div class="col-md-6">
                  <div class="form-group">
                    <label>Role</label>
                       <select  v-model="user.role" class="form-control">
                          <option value="user1">user1</option>
                          <option value="user2">user2</option>
                          <option value="manager">admin</option>
                        </select>
                  </div>
        </div>


      </div>
      <div class="row">




        <div class="col-md-6">
          <div class="form-group">
            <label>telefon</label>
            <input type="text" v-model="user.tel" class="form-control" />
          </div>
        </div>

      </div>
      <div class="row" v-if="user.id == undefined || user.id == null ">
      <div class="col-md-12">
              <div class="form-group">
                <input type="checkbox" v-model="send_email" class="checkbox"/>  odeslat pozvánku emailem
              </div>
      </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>e-mail</label>
            <input type="text" v-model="user.email" class="form-control" />
          </div>
        </div>

<!--
        <div class="col-md-6 form-group">
          <label>Heslo</label>
          <input type="password" v-model="user.password" class="form-control" />
        </div>-->

      </div>



    </div>
    <div class="modal__action">
      <button  class="btn btn-primary" @click="handleSaveChanges">uložit změny</button>
      <button class="btn btn-outline-dark ml-4" @click="showM=false">zrušit</button>
    </div>
  </vue-final-modal>

</template>

<script>
import axios from "axios";
import {VueFinalModal} from "vue-final-modal";
import modalKkMixin from "@/lib/modal-kk-mixin";

export default {
  name: "modal-kk-user"  ,
  components: { VueFinalModal },
  props:['user'],
  mixins:[modalKkMixin],
  data(){
    return({
      organizations:[]
    })
  },
  mounted() {

  },
  watch:{
    user(){

    }
  },
  methods:{

    handleSaveChanges(){
      if (this.user.id>0){

        axios.put(`/users/${this.user.id}`, { user: this.user }).then(response =>{
          this.$toasted.show("Změny uloženy", {type: 'success'});
          this.$emit('userUpdated')
          this.hideModal()
        })

      }else{
        this.createuser()
      }
    },

    createuser(){
      const formData = { user: this.user }
      axios.post('/users/member', formData).then(response =>{
        this.$emit('userUpdated')
       // this.$toasted.show("Změny uloženy", {type: 'success'});
        this.hideModal()
      })
    }
  }
}
</script>

<style scoped>

</style>