<script>
export default {
  name: "drawings",
  props:['motor', 'docs'],
  data(){
    return{
      pdf: '',
      img:''
    }
  },
  created() {

    console.log('D', this.docs.datasheet[0].file_url)
    this.pdf = this.docs.datasheet[0].file_url

  },

}
</script>

<template>
  <h2>Drawing</h2>
  <img :src="docs.drawing[0].file_url"   :alt="docs.drawing[0].file_url" style="max-width: 100%"/>
</template>

<style scoped>

</style>