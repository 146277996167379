

import axios from "axios";

window.translations=[]

export function localesLoader(next){

  axios.get('/default/translations.json').then(result => {
    window.translations = result.data.translations
    next()
  })


}