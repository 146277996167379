<template>
  <div :class="`d-inline-block member m-1 ${m.confirmed_at == '' || m.confirmed_at==null ? 'neakt':''}`">
    <div class="single p-1">
      <div class="media">
        <div class="icon flex-fill align-self-center text-success">
          <img class="mx-2" :src="`/img/clen-teamu.png`" alt />
        </div>
      </div>
      <div class="detail mt-3 mb-2">
        <div class="row m-0">
          <div class="col col-12">
            <div class="row mb-1">
              <div class="col col-5 font-weight-bold pr-1">Jméno:</div>
              <div class="col col-7 font-weight-bold bg-white text-break pl-1">{{m.name || m.email}}</div>
            </div>
            <div class="row mb-1">
              <div class="col col-5 font-weight-bold pr-1">Společnost:</div>
              <div class="col col-7 bg-white text-break pl-1">{{company || m.company_name}}</div>
            </div>
            <div class="row mb-1" >
              <div class="col col-5 font-weight-bold pr-1">Role:</div>
              <div class="col col-7 bg-white text-break pl-1" v-if="account_id>0">

                <span>{{$t(`role.${m.role}`)}}</span>

                <img @click="showEditatom(m)" v-if="!m.visiblesel && my_role=='superadmin'" class="tuzka" :src="`/img/pencil-icon-icons-20.png`" />

                <select   v-if="m.visiblesel" @change="changeUserRole(m)" v-model="m.account_role">
                  <option value="user1">user1</option>
                  <option value="user2">user2</option>
                  <option value="manager">admin</option>
                </select>
              </div>
              <div v-else> <span>{{$t(`role.${m.role}`)}}</span></div>
            </div>

            <!-- <div class="row mb-1">
                <div class="col col-5 font-weight-bold pr-1">Email:</div>
                <div class="col col-7 bg-white text-break pl-1">{{m.email}}</div>
            </div> -->
            <div class="row mb-1" v-if="my_role=='admin'">
              <div class="col col-12 font-weight-bold pr-1">
                <router-link :to="'/main/motors?user_id='+m.id">{{$t('page.seznam-motoru')}} ></router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


import axios from 'axios'
import moment from "moment";
import {translator} from "@/lib/translator";

moment.locale('cs');
export default {
  name: "TeamMember",
  props:['m', 'my_role', 'company', 'account_id'],
  computed: {
    me() {
      if (this.$store.getters.me){
        console.log('teammme', this.$store.getters.me.id)
        return this.$store.getters.me;
      }
      else
        return {}
    },
    mamUcet(){
      return true
      //return this.me.account_type == 'company'
    }
  },
  methods:{
    $t: translator,
    c_rolename(r){
      if (r>10) return 'SuperAdmin'
      if (r>=3) return "Admin"
      if (r>1) return "MAnager"
      return "Technik"
    },
    df: function (time, format) {
      moment.locale(this.$i18n.locale);
      var a = moment(time);
      return a.format(format ? format : "LLL")
    },
    showEditatom(m){
      m.visiblesel=true
      console.log('sEET', m.id, m.visiblesel)
      this.$forceUpdate()
    },
    changeUserRole(m){


      axios.put(process.env.VUE_APP_API_ROOT_URL + "/account/update_role", {account_id: this.account_id,  id: m.id, role: m.account_role}).then(result => {
        console.log('res', result.status, result.data)
      }).catch(e => {
        console.log(e.response.data)
        var d = e.response.data
        if (d.error){
          this.$toasted.show( d.error,{ fullWidth: true, iconPack: 'fontawesome', type: 'error' ,duration : 5000})
          this.$parent.loadTeam()
        }
      })
      m.visiblesel=false
    }
  }
}
</script>

<style scoped>
.single .detail{
  cursor: pointer;
}

.neakt{
  opacity: 0.5;
}

</style>
