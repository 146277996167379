<script>

import MotorAttributes from "@/entrypoints/admin/MotorAttributes.vue";
import cagpdf from "@/entrypoints/components/help/cagpdf.vue";
export default {
  name: "plate",
  components: {MotorAttributes,cagpdf },
  props:['motor', 'docs'],
  data(){
    return{
      pdf: ''
    }
  },
  created() {

    console.log('D', this.docs.datasheet[0].file_url)
    this.pdf = this.docs.datasheet[0].file_url

  },



}

</script>

<template>
  <cagpdf :pdf="pdf"></cagpdf>
</template>

<style scoped lang="scss">


</style>