<script setup>


import { ref, watch } from 'vue'

import { VuePDF, usePDF } from '@tato30/vue-pdf'
import '@tato30/vue-pdf/style.css'

const props = defineProps(['pdf'])

const { pdf , pages } = usePDF(props.pdf)


</script>
<script>
export default {
  name: "cagpdf",
};
</script>
<template>
  <div v-for="page in pages" :key="page" class="pdf_page">
    <VuePDF :pdf="pdf" :page="page" />
  </div>
</template>

<style scoped lang="scss">

.pdf_page{
  display: flex;
  div{
    margin: auto;
    margin-bottom:10px;
  }

}
</style>