
import {VueFinalModal} from "vue-final-modal";
import moment from "moment/moment";
import 'vue-final-modal/style.css'
import './modal.scss'
import {translator} from "@/lib/translator";
const modalKkMixin={

   data(){
    return({
      showM: false
    })
  },
  methods:{
     $t: translator,
     df(s){
        return moment(s).format('DD.MMMM YYYY')
     },
    dt(s){
      return moment(s).format('DD. MM. YYYY hh:mm:ss a')
    },
    showModal(){
      this.showM=true
    },
    hideModal(){
      this.showM=false
    }
  },
  components: {
    VueFinalModal
  }
}
export default modalKkMixin