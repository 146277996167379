<script>
import axios from "axios";
import MotorMixin from "@/entrypoints/admin/motorMixin";
import Plate from "@/entrypoints/components/help/plate.vue";
import Datasheet from "@/entrypoints/components/help/datasheet.vue";
import Drawings from "@/entrypoints/components/help/drawings.vue";
import Manual from "@/entrypoints/components/help/manual.vue";

export default {
  name: "main-help",
  components: {Manual, Drawings, Datasheet, Plate},
  mixins:[MotorMixin],
  props: ['mm'],
  data(){
    return {
      motorSnInput:'',
      tab:0,
      err:'',
      manu:{},
      motor:{},
      docs:{manual:[], drawing:[], datasheet:[]}
    }
  },
  mounted() {
    if (this.mm && this.mm!='null') {
      this.manu=JSON.parse(this.mm)
      if (this.manu.id>0) this.loadDetail(this.manu.id)
    }
  },
  methods:{
    handleLoadMotor(){
      this.searchMotor(this.motorSnInput)
    },

    searchMotor(sn){
      // help-data/search/:sn' => 'help_data#search
      axios.get(`/help-data/search/${sn}`).then(response =>{
        location.href=response.data.link
      }).catch(err=>{
        if (err.response.status==404){
          this.err=`Motor ${sn} nenalezen.`
          this.motor={}
        }
      })
    },

    loadDetail(mm_id){
      axios.get(`/help-data/${mm_id}`).then(response =>{

        let motors = [response.data.mm]
        motors = this.expand_motorcocde(motors)
        this.motor = motors[0]

        for(let i in response.data.documents){
          let d = response.data.documents[i]
          this.docs[d.kind].push(d)
        }
      }).catch(err=>{
        console.log(';neni tam', err.response.status)
        if (err.response.status==404){
          this.err=`Motor ${mm_id} nenalezen.`
          this.motor={}
        }
      })
    },

    openTab(n){
      this.tab=n
    }

  }

}
</script>

<template>

  <div v-if="!motor.id">

    nejdriv zadej motor
    <input type="text" v-model="motorSnInput"/><button @click="handleLoadMotor">ok</button>


    <div class="alert-danger alert m-3" v-if="err && err.length>1">
      {{err}}
    </div>

  </div>
  <template v-else>

    <div class="row">
      <div class="col-md-6" @click="tab=0">
        <span class="gc">Motor:</span> {{motor.typ}}/{{motor.power}}kW  <br>
        <span class="gc">Prod. N.: Motor:</span> {{motor.motor_sn}}
      </div>
    </div>



    <plate :motor="motor" v-if="tab==1" @close="tab=0"></plate>
    <datasheet :motor="motor" :docs="docs" v-if="tab==2" @close="tab=0"></datasheet>
    <drawings :motor="motor" :docs="docs" v-if="tab==3" @close="tab=0"></drawings>
    <manual  :motor="motor" :docs="docs" v-if="tab==4" @close="tab=0"></manual>


<div class="row" v-if="tab==0">

  <div class="dlazka col-md-4 col-6">
    <div class="dl-content plate" @click="openTab(1)">
      <div class="dl-title">Digitální štítek</div>
    </div>
  </div>
  <div class="dlazka col-md-4 col-6">
    <div class="dl-content datasheet" @click="openTab(2)"> <div class="dl-title">Datasheet</div>


    </div>
  </div>


  <div class="dlazka col-md-4 col-6"> <div class="dl-content drawings" @click="openTab(3)">
    <div class="dl-title">výkres</div>



  </div>
  </div>

  <div class="dlazka col-md-4 col-6">
    <div class="dl-content manual" @click="openTab(4)">
      <div class="dl-title">manual</div>
    </div>
  </div>

  <div class="dlazka col-md-4 col-6">
    <div class="dl-content faq" @click="openTab(5)">
      <div class="dl-title">FAQs</div>
    </div>
  </div>

  <div class="dlazka col-md-4 col-6">
    <div class="dl-content hotline" @click="openTab(6)">
      <div class="dl-title">Hotline 24/7</div>
    </div>
  </div>





  <div v-for="d in docs.manual">
    <a :href="d.file_url">{{d.name}}</a>
  </div>
</div>

    <div class="text-center" v-else>
      <button class="back" @click="tab=0">Back</button>
    </div>


  <div v-for="d in docs.drawing">
    <a :href="d.file_url">{{d.name}}</a>
  </div>


  <div v-for="d in docs.datasheet">
    <a :href="d.file_url">{{d.name}}</a>
  </div>

  </template>



</template>

<style lang="scss">
.dlazka{
  padding:15px;


  .dl-content {
    min-height: 300px;
    background: transparent;
    box-shadow: 1px 1px 4px #333;

    border: 1px solid green;
    border-radius: 20px;
    text-align: center;


    background-position: center center;
    background-repeat: no-repeat;

    &.hotline{
      background-image: url('/img/cico/hotline.png');
    }
    &.faq{
      background-image: url('/img/cico/faq.png');
    }

    &.manual{
      background-image: url('/img/cico/manual.png');
    }

    &.drawings{
      background-image: url('/img/cico/draw.png');
    }
  &.plate{
      background-image: url('/img/cico/plate.png');
    }
    &.datasheet{
      background-image: url('/img/cico/datasheet.png');
    }
    .dl-title{
      margin-top: 200px;
      font-size: 200%;
    }

  }


}

button.back{
  color: white;

  background: url('/img/s4.svg') no-repeat left 4px;
  background-size: 30px ;
  padding-left: 40px;

  font-size:120%;

  border: none;
  height:30px;


}

</style>