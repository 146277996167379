<template>
<div class="card-body">


  <div class="row">

    <div class="col-md-12">
      <h5 class="my-3">Čekají na schválení</h5>
      <CompaniesTable :companies="waitingAccounts"></CompaniesTable>

      <h5 class="my-3">Zamitnute firmy</h5>
      <CompaniesTable :companies="rejectedAccounts"></CompaniesTable>

    </div>
  </div>

  <ModalCompany ref="formCompany" :company="current_company" @companyUpdated="handleUpdated"></ModalCompany>

  <button @click="handleAddCompany">nova firma</button>
</div>
</template>

<script>

import CompaniesTable from "./CompaniesTable.vue";
import axios from 'axios'
import moment from "moment";
import {translator} from "@/lib/translator";
//require('moment/locale/cs');
moment.locale('cs');

import ModalCompany from "@/entrypoints/admin/ModalCompany.vue";
export default {
  name: "Companies",
  components: {ModalCompany, CompaniesTable},
  mounted() {
    console.log('mounted companies')
    this.refreshAccounts()
  },
  data(){
    return{
      waitingAccounts: [],
      rejectedAccounts:[],
      companies:[],
      current_company:{}
    }
  },
  methods:{
    handleAddCompany(){
      this.current_company={}
      this.$refs.formCompany.showModal()
    },


    $t: translator,
    acceptCompany(a){
        axios.get(
                "/companies/accept/"+a.id)
            .then(result => {
                if (result.status == 200){
                  this.refreshAccounts()
                }
            })
    },
    rejectCompany(a){
      axios.get(
                "/companies/reject/"+a.id)
            .then(result => {
              if (result.status == 200){
                  this.refreshAccounts()
                }
            })

    },
    handleUpdated(){
        this.refreshAccounts()
    },

    refreshAccounts(){
          axios.get("/companies/waiting").then(result => {
                this.waitingAccounts = result.data.companies
            })

            axios.get(       "/companies/rejected").then(result => {
                this.rejectedAccounts = result.data.companies
              })
      /*    axios.get(
              "/companies/index")
              .then(result => {
                this.companies = result.data.companies
              })
*/
      }
  }
}
</script>

<style scoped>

</style>
