<template>
  <div class="">

    <h4>Template generator  {{$t('tooltip.qr.Product')}}</h4>

    <div class="row">
      <div class="col colkal1">

             <table class="kalkulator">
          <tr>
            <th>  </th>
            <td colspan="2"><small>Product</small><br>
              <i class="napoveda" v-tooltip.top-center="$t('tooltip.qr.Product')">i</i>
            </td>
            <td colspan="10">		Producer <i class="napoveda" v-tooltip.top-center="$t('tooltip.qr.Producer')">i</i></td>
            <td colspan="10">										Model <i class="napoveda" v-tooltip.top-center="$t('tooltip.qr.Model')">i</i></td>
            <td colspan="3">										Size <i class="napoveda" v-tooltip.top-center="$t('tooltip.qr.Size')">i</i></td>
            <td colspan="3">			Length <i class="napoveda" v-tooltip.top-center="$t('tooltip.qr.Length')">i</i></td>
            <td colspan="2">			Poles <i class="napoveda" v-tooltip.top-center="$t('tooltip.qr.Poles')">i</i></td>
            <td colspan="7">		Power <i class="napoveda" v-tooltip.top-center="$t('tooltip.qr.Power')">i</i></td>
            <td colspan="3">							Mounting	 <i class="napoveda" v-tooltip.top-center="$t('tooltip.qr.Mounting')">i</i></td>
            <td colspan="3">		Voltage 1 <i class="napoveda" v-tooltip.top-center="$t('tooltip.qr.Voltage1')">i</i></td>
            <td colspan="3">			Voltage 2	 <i class="napoveda" v-tooltip.top-center="$t('tooltip.qr.Voltage2')">i</i></td>
            <td colspan="2">		Freq. 1 <i class="napoveda" v-tooltip.top-center="$t('tooltip.qr.Freq1')">i</i></td>
            <td colspan="2">		Freq. 2 <i class="napoveda" v-tooltip.top-center="$t('tooltip.qr.Freq2')">i</i></td>


            <td colspan="1">		Insulation class <i class="napoveda" v-tooltip.top-center="$t('tooltip.qr.Insulation_class')">i</i></td>
            <td colspan="1">		Temeprature rice <i class="napoveda" v-tooltip.top-center="$t('tooltip.qr.Temeprature_rice')">i</i></td>

          </tr>

          <tr>
            <th>Position</th>
            <td v-for="index in 50" :key="index">{{index}}</td>
            <td>121</td>
            <td>122</td>
          </tr>



          <tr>
            <th>Code </th>
            <td colspan="2" class="mrb" >
              <mrizkobunka type="text" :maxlength="2" style="left:31px;" v-model="qr[0]" :ro="true"/>
            </td>
            <td colspan="10" class="mrb"><mrizkobunka type="text" :maxlength="10"
                                                                 v-model="qr[1]" :ro="false" @input="onMbChange"/></td>
            <td colspan="10" class="mrb"><mrizkobunka type="text" :maxlength="10" v-model="qr[2]"/></td>

            <td colspan="3" class="mrb"><mrizkobunka type="text" :maxlength="3" v-model="qr[3]" :ro="true"/>


            </td>

            <td colspan="3" class="mrb"><mrizkobunka type="text" :maxlength="3" v-model="qr[4]" :ro="true"/></td>
            <td colspan="2" class="mrb">
              <mrizkobunka type="text" :maxlength="2" v-model="qr[5]" :ro="true"/>

            </td>


            <td colspan="7" class="mrb"><mrizkobunka type="text" :maxlength="7" v-model="qr[6]" :ro="true"/>


            </td>
            <td colspan="3" class="mrb">
              <mrizkobunka type="text" :maxlength="3" v-model="qr[7]" :ro="true"/>
            </td>
            <td colspan="3" class="mrb">
              <mrizkobunka type="text" :maxlength="3" v-model="qr[8]" :ro="true"/>

            </td>
            <td colspan="3" class="mrb">
              <mrizkobunka type="text" :maxlength="3" v-model="qr[9]" :ro="true"/>

            </td>

            <td colspan="2" class="mrb">
              <mrizkobunka type="text" :maxlength="2" v-model="qr[10]" :ro="true"/>
            </td>
            <td colspan="2" class="mrb">
              <mrizkobunka type="text" :maxlength="2" v-model="qr[11]" :ro="true"/>
            </td>


            <td colspan="1" class="mrb">
              <mrizkobunka type="text" :maxlength="1" v-model="qr[25]" :ro="true"/>
            </td>
            <td colspan="1" class="mrb">
              <mrizkobunka type="text" :maxlength="1" v-model="qr[26]" :ro="true"/>
            </td>


          </tr>

          <tr class="selectrow">
            <th>Select </th>
            <td colspan="2" class="mrb"><!--<mrizkobunka type="text" :maxlength="2"  v-model="qr[0]"/>-->
              <select v-model="qr[0]"  style="min-width: 105px;">
                <option value="1">	Electric motor</option>
                <option value="2">	  	Fan</option>
                <option value="3">	  	Pump</option>
                <option value="4">	  	Gear</option>

              </select>

            </td>
            <td colspan="10" class="mrb"></td>
            <td colspan="10" class="mrb"></td>

            <td colspan="3" class="mrb"><!--<mrizkobunka type="text" :maxlength="3" v-model="qr[3]"/>-->
              <select v-model="qr[3]" style="">
                <option>56</option>
                <option>63</option>
                <option>71</option>
                <option>80</option>
                <option>90</option>
                <option>100</option>
                <option>112</option>
                <option>132</option>
                <option>160</option>
                <option>180</option>
                <option>200</option>
                <option>225</option>
                <option>250</option>
                <option>280</option>
                <option>315</option>
                <option>355</option></select>


            </td>

            <td colspan="3" class="mrb">
              <select v-model="qr[4]" style="">
                <option>S</option>
                <option>S1</option>
                <option>S2</option>
                <option>S3</option>
                <option> M</option>
                <option> M1</option>
                <option> M2</option>
                <option>M3</option>
                <option>L</option>
                <option>L1</option>
                <option> L2</option>
                <option> L3</option>

                <option> 1</option>
                <option> 2</option>
                <option> 3</option>



              </select>


            </td>
            <td colspan="2" class="mrb">
              <!--<mrizkobunka type="text" :maxlength="2" v-model="qr[5]"/> -->
              <select v-model="qr[5]" style="">
                <option>2</option>
                <option>4</option>
                <option>6</option>
                <option>8</option>
                <option>10</option>
              </select>

            </td>


            <td colspan="7" class="mrb"><!--<mrizkobunka type="text" :maxlength="7" v-model="qr[6]"/>-->

              <select v-model="qr[6]" style=" width:100%">
                <option v-for="k in formatedKilowat" :key="k.value" :value="k.value" >{{k.text}}</option>
              </select>

            </td>
            <td colspan="3" class="mrb">
              <!--<mrizkobunka type="text" :maxlength="3" v-model="qr[7]"/>-->
              <select v-model="qr[7]" style="">
                <option>B_3</option>
                <option>B_5</option>
                <option>B14</option>
                <option>B35</option>
                <option>B34</option>
                <option>B_6</option>
                <option>B_7</option>
                <option>V_1</option>
                <option>V_5</option>
                <option>V69</option>
                <option>V19</option>
                <option>V36</option>
              </select>

            </td>
            <td colspan="3" class="mrb">
              <!--<mrizkobunka type="text" :maxlength="3" v-model="qr[8]"/>-->
              <select v-model="qr[8]" style=" ">
                <option v-for="k in voltySelect" :key="k.value" :value="k.value" >{{k.text}}</option>
              </select>
            </td>
            <td colspan="3" class="mrb">
              <!--<mrizkobunka type="text" :maxlength="3" v-model="qr[9]"/>-->
              <select v-model="qr[9]" style=" ">
                <option v-for="k in voltySelect" :key="k.value" :value="k.value" >{{k.text}}</option>
              </select>
            </td>

            <td colspan="2" class="mrb">
              <!--<mrizkobunka type="text" :maxlength="2" v-model="qr[10]"/>-->
              <select v-model="qr[10]" style="">
                <option value="50">50</option>
                <option value="60">60</option>
              </select>
            </td>
            <td colspan="2" class="mrb">
              <!--<mrizkobunka type="text" :maxlength="2" v-model="qr[11]"/>-->
              <select v-model="qr[11]" style="">
                <option value="50">50</option>
                <option value="60">60</option>
              </select>
            </td>



            <td colspan="1" class="mrb">
              <!--<mrizkobunka type="text" :maxlength="2" v-model="qr[11]"/>-->
              <select v-model="qr[25]" style="">
                <option >Y</option>
                <option >A</option>
                <option >E</option>
                <option >B</option>
                <option >F</option>
                <option >H</option>
              </select>
            </td>




            <td colspan="1" class="mrb">
              <!--<mrizkobunka type="text" :maxlength="2" v-model="qr[11]"/>-->
              <select v-model="qr[26]" style="">
                <option >Y</option>
                <option >A</option>
                <option >E</option>
                <option >B</option>
                <option >F</option>
                <option >H</option>
              </select>
            </td>


          </tr>



          <tr class="pozky">
            <th> Number of positions</th>
            <td colspan="2">2</td>
            <td colspan="10">10 </td>
            <td colspan="10">10 </td>
            <td colspan="3">3</td>
            <td colspan="3">3	</td>
            <td colspan="2">2	</td>
            <td colspan="7">7	</td>
            <td colspan="3">3	</td>
            <td colspan="3">3	</td>
            <td colspan="3">3	</td>
            <td colspan="2">2	</td>
            <td colspan="2">2	</td>

            <td colspan="1">1	</td>
            <td colspan="1">1	</td>


          </tr>






        </table>
        <!------------------------------------------------------------------------------------------------>

        <table class="kalkulator mt-3">
          <tr>
            <th rowspan="2" style="width: 10%">  </th>
            <td colspan="11">		Bearings DE	</td>

            <td colspan="11">					Bearings NDE				</td>

            <td colspan="8" rowspan="2">Winding protection</td>

            <td colspan="6" rowspan="2">DE Bearing protection</td>
            <td colspan="6" rowspan="2">NDE Bearing protection</td>


          </tr>
          <tr>

            <td colspan="5">		x <i class="napoveda" v-tooltip.top-center="$t('tooltip.qr.BearingsDE_1')">i</i>	</td>
            <td colspan="2">		x <i class="napoveda" v-tooltip.top-center="$t('tooltip.qr.BearingsDE_2')">i</i>	</td>
            <td colspan="4">		x <i class="napoveda" v-tooltip.top-center="$t('tooltip.qr.BearingsDE_3')">i</i>	</td>

            <td colspan="5">		x <i class="napoveda" v-tooltip.top-center="$t('tooltip.qr.BearingsNDE_1')">i</i>	</td>
            <td colspan="2">		x <i class="napoveda" v-tooltip.top-center="$t('tooltip.qr.BearingsNDE_2')">i</i>	</td>
            <td colspan="4">		x <i class="napoveda" v-tooltip.top-center="$t('tooltip.qr.BearingsNDE_3')">i</i>	</td>

          </tr>

          <tr>
            <th>Position</th>
            <td v-for="index in (22)" :key="index">{{index+50}}</td>
            <td v-for="index in (8+6+6)" :key="index+1000" class="small">{{index+100}}</td>

          </tr>



          <tr class="pozky">
            <th>Code</th>
            <td colspan="5" class="mrb ">
              <mrizkobunka type="text" :maxlength="5" v-model="qr[12]" :ro="true"/>

            </td>

            <td colspan="2" class="mrb "><mrizkobunka  :ro="true" type="text" :maxlength="2"   v-model="qr[13]"/>


            </td>
            <td colspan="4" class="mrb "><mrizkobunka  :ro="true" type="text" :maxlength="4"  v-model="qr[14]" />            </td>
            <td colspan="5" class="mrb">           <mrizkobunka type="text" :maxlength="5"  v-model="qr[15]"  :ro="true"/>
            </td>
            <td colspan="2" class="mrb">
              <mrizkobunka type="text"  :ro="true" :maxlength="2"  v-model="qr[16]" />
            </td>
            <td colspan="4" class="mrb">
              <mrizkobunka type="text" :maxlength="4"  v-model="qr[17]"  :ro="true"/>
            </td>

            <td colspan="8" class="mrb"><mrizkobunka type="text" :maxlength="8"  v-model="qr[22]"/></td>
            <td colspan="6" class="mrb"><mrizkobunka type="text" :maxlength="6"  v-model="qr[23]"/></td>
            <td colspan="6" class="mrb"><mrizkobunka type="text" :maxlength="6"  v-model="qr[24]"/></td>



          </tr>


          <tr class="pozky selectrow">
            <th>Select</th>
            <td colspan="5" class="mrb ">
              <!--<mrizkobunka type="text" :maxlength="5" v-model="qr[12]"/>-->
              <select v-model="qr[12]" style="width: 100%">
                <optgroup :label="n" v-for="(l, n) in loziska" :key="n" >
                  <option v-for="v in l" :key="v" :value="v.toString()">{{v}}</option>
                </optgroup>
              </select>
            </td>

            <td colspan="2" class="mrb "><!--<mrizkobunka type="text" :maxlength="2"  v-model="qr[13]"/>-->

              <select v-model="qr[13]" style="">
                <option>C0</option>
                <option>C1</option>
                <option>C2</option>
                <option>C3</option>
                <option>C4</option>
                <option>C5</option>
              </select>
            </td>
            <td colspan="4" class="mrb "><!--<mrizkobunka type="text" :maxlength="3"  v-model="qr[14]"/>-->
              <select v-model="qr[14]" class="w-100">
                <option>Open</option>
                <option>Z</option>
                <option>2Z</option>
                <option>ZR</option>
                <option>2ZR</option>
                <option>RS</option>
                <option>2RS</option>
                <option>RSR</option>
                <option>2RSR</option>
              </select>
            </td>






            <td colspan="5" class="mrb">
              <!--<mrizkobunka type="text" :maxlength="5"  v-model="qr[15]"/>-->
              <select v-model="qr[15]" style="width: 100%">
                <optgroup :label="n" v-for="(l, n) in loziska" :key="n" >
                  <option v-for="v in l" :key="v" :value="v.toString()">{{v}}</option>
                </optgroup>
              </select>

            </td>
            <td colspan="2" class="mrb">
              <!--<mrizkobunka type="text" :maxlength="2"  v-model="qr[16]"/>-->
              <select v-model="qr[16]" style="">
                <option>C0</option>
                <option>C1</option>
                <option>C2</option>
                <option>C3</option>
                <option>C4</option>
                <option>C5</option>
              </select>

            </td>
            <td colspan="4" class="mrb"><!--<mrizkobunka type="text" :maxlength="3"  v-model="qr[17]"/>-->

              <select v-model="qr[17]" class="w-100">
                <option>Open</option>
                <option>Z</option>
                <option>2Z</option>
                <option>ZR</option>
                <option>2ZR</option>
                <option>RS</option>
                <option>2RS</option>
                <option>RSR</option>
                <option>2RSR</option>
              </select>
            </td>


            <td colspan="8" class="mrb "><!--<mrizkobunka type="text" :maxlength="3"  v-model="qr[14]"/>-->
              <select v-model="qr[22]" class="w-100">
                <option v-for="k in windingProtectionSelect" :key="`wp${k.value}`" :value="k.value" >{{k.text}}</option>
              </select>
            </td>
            <td colspan="6" class="mrb">
              <select v-model="qr[23]" class="w-100">
                  <option value="no">No protection</option>
                  <option>PTC</option>
                  <option>PTO</option>
                  <option>PT100</option>
              </select>
            </td>
            <td colspan="6" class="mrb">
              <select v-model="qr[24]" class="w-100">
                <option value="no">No protection</option>
                <option>PTC</option>
                <option>PTO</option>
                <option>PT100</option>
              </select>
            </td>




          </tr>



          <tr>
            <th> Number of positions</th>

            <td colspan="5">5	</td>
            <td colspan="2">2	</td>
            <td colspan="4">4	</td>

            <td colspan="5">5	</td>
            <td colspan="2">2	</td>
            <td colspan="4">4	</td>

             <td colspan="8">8	</td>

            <td colspan="6">6	</td>
            <td colspan="6">6	</td>



          </tr>

        </table>


        <div class="d-flex">
          <table class="kalkulator mt-3" style="width: 690px; min-width:640px; ">
          <tr>
            <th style="width: 10%">  </th>
            <td colspan="4" rowspan="1">						Color	 <i class="napoveda" v-tooltip.top-center="$t('tooltip.qr.Color')">i</i>	</td>
            <td colspan="4" rowspan="1">		Weight		 <i class="napoveda" v-tooltip.top-center="$t('tooltip.qr.Weight')">i</i>	</td>
            <td colspan="10" rowspan="1">	Production number			 <i class="napoveda" v-tooltip.top-center="$t('tooltip.qr.Production_number')">i</i>			</td>
            <td colspan="10" rowspan="1">				Companies ID <i class="napoveda" v-tooltip.top-center="$t('tooltip.qr.CompaniesID')">i</i></td>

          </tr>
          <tr>
            <th>Position</th>
            <td v-for="index in (28)" :key="index+10000">{{index+50+22}}</td>


          </tr>

        <tr class="pozky">
            <th>Code</th>
          <td colspan="4" class="mrb"><mrizkobunka type="text" :maxlength="4"  v-model="qr[18]"/></td>
          <td colspan="4" class="mrb"><mrizkobunka type="text" :maxlength="4"  v-model="qr[19]"/></td>
          <td colspan="10" class="mrb"><mrizkobunka type="text" :maxlength="10"  v-model="qr[20]"/></td>
          <td colspan="10" class="mrb"><mrizkobunka type="text" :maxlength="10"  v-model="qr[21]" :ro="true"/></td>
        </tr>

 <!--         <tr class="pozky selectrow">
            <th>Select</th>
            <td colspan="4" class="mrb"></td>
            <td colspan="4" class="mrb"></td>
            <td colspan="10" class="mrb"></td>
            <td colspan="10" class="mrb"></td>

          </tr>
-->

            <tr>
            <th> Number of positions</th>

              <td colspan="4">4	</td>
              <td colspan="4">4	</td>
              <td colspan="10">10	</td>
              <td colspan="10">10</td>

            </tr>



        </table>


          <div class="podpora">
            Podpora


            <v-select v-model="selected_datasheets" @search="onSearch" :filterable="false" :options="searchoptions_datasheets" multiple="true"
                      placeholder="Datasheety">
              <template #option="option">
                <div>         {{ option.name }}      </div>
              </template>
              <template #selected-option="option">
                <div class="selected">        {{ option.name }}       </div>
              </template>
            </v-select>

            <v-select v-model="selected_drawings" @search="onSearch" :filterable="false" :options="searchoptions_drawings" multiple="true"
                      placeholder="Vykresy">
              <template #option="option">
                <div>         {{ option.name }}      </div>
              </template>
              <template #selected-option="option">
                <div class="selected">        {{ option.name }}       </div>
              </template>
            </v-select>

            <v-select v-model="selected_manuals" @search="onSearch" :filterable="false" :options="searchoptions_manuals" multiple="true"
                      placeholder="Navody">
              <template #option="option">
                <div>         {{ option.name }}      </div>
              </template>
              <template #selected-option="option">
                <div class="selected">        {{ option.name }}       </div>
              </template>
            </v-select>



          </div>


        </div>








      </div>
      <div class=" text-left colkal2" >
       <!-- <qrcode class="m-2" :value="kod" :options="{ width: '100%', errorCorrectionLevel:'L' }"  ></qrcode>-->


        <div v-html="svgp" style="background: white; padding:5px;display: inline-block"></div>

        <div>
            <input type="radio" id="one" value="1" v-model="svg_template" name="svgt"/>
            <label for="one" class="ml-1">full variant</label>
            <br>
            <input type="radio" id="two" value="2" v-model="svg_template" name="svgt"/>
            <label for="two" class="ml-1">qr code only</label>
            <br><span>Picked: {{ svg_template }}</span>
        </div>
      </div>

    </div>



    <!---------------------------------------------------------------------------------------->

    <div class="row mt-2">

      <div class="col-md-10 text-right">
        <button class="btn btn-outline-secondary mr-1" @click.prevent="btnCancel" >{{$t('admin.cancel')}}</button>
        <button class="btn btn-primary mr-1" @click.prevent="btnSave" >{{$t('admin.save')}}</button>
        <button v-if="edited_id" class="btn btn-primary mr-1" @click.prevent="btnSaveAsNew" >{{$t('qrcode.save-as-new')}}</button>
      </div>

    </div>

    <div class="row mt-3">
      <div class="col-md-12">
        <div class="form-control vysledek">{{kod}}</div>
      </div>




      <div class="col-md-12 mt-2">

        <div class="card">

          <div class="card-body" style="min-height: 300px;">
            <h4>Templates</h4>

            <table class="table table-striped" id="seznam">
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Producer</th>
                  <th>Model</th>
                  <th>Power</th>
                  <th>Mounting</th>
                  <th>Production number</th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>

                <tr>
                  <th><select v-model="filrer_product"  style="min-width: 105px;">
                    <option value="0">	All</option>
                    <option value="1">	Electric motor</option>
                    <option value="2">	  	Fan</option>
                    <option value="3">	  	Pump</option>
                    <option value="4">	  	Gear</option>
                  </select></th>
                  <th><select v-model="filter_producer">
                    <option value="">	All</option>
                    <option v-for="opt in producer_options" :key="opt.value" :value="opt.value">{{opt.label}}</option>
                  </select></th>
                  <th>
                    <select v-model="filter_model">
                    <option value="">	All</option>
                    <option v-for="opt in model_options" :key="opt.value" :value="opt.value">{{opt.label}}</option>
                  </select>
                  </th>
                  <th>
                  <select v-model="filter_power">
                    <option value="">	All</option><option v-for="opt in power_options" :key="opt.value" :value="opt.value">{{opt.label}}</option>
                  </select>
                  </th>
                  <th>
                    <select v-model="filter_mount">
                      <option value="">	All</option><option v-for="opt in mount_options" :key="opt.value" :value="opt.value">{{opt.label}}</option>
                    </select>
                  </th>
                  <th> </th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>

              </thead>

              <tbody>
              <tr v-for="c in codes" :key="c.id">
                <th>{{ product_from_qr(c) }}</th>
                <th>{{ c.params[1] }}</th>
                <th>{{ c.params[2] }}</th>
                <th>{{ c.params[6] * 10 }}</th>
                <th>{{ c.params[7] }}</th>
                <th>{{c.params[20]}}</th>

        <!--
                <td class="align-middle skodem">
                  <div class="linkakod">{{c.code}}</div>
                </td>-->
                <td><qrcode class="" :value="c.code" :options="{ width: 60, errorCorrectionLevel:'L' }"  ></qrcode></td>
                <td class="p-1 pr-2 align-middle">{{df(c.created_at, 'LL')}}</td>
                <td class="btntd align-middle">
                  <button class="btn btn-white btn-outline-dark btn-sm" @click="destroyCode(c)">{{ $t('admin.delete') }}</button>
                </td>
                <td class="btntd align-middle">
                  <button class="btn btn-primary btn-sm" @click="openForEdit(c)">{{ $t('admin.edit') }}</button>
                </td>
                <td class="btntd2 align-middle">
                  <button class="btn btn-secondary btn-sm" @click.prevent="pdfPrintDialog(c)">{{ $t('admin.generate-qr') }}</button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div>

 <!--   <a href="#" data-toggle="modal"  data-target="#qr-pdf-modal" id="spoustec-modalu-qr"></a>
-->

    <QrToPdf :kod="kod_for_print" ref="qrtopdfdialog"></QrToPdf>

  </div>
</template>

<script>
import Mrizkobunka from "./components/mrizkobunka.vue";
//const moment = require('moment');
//require('moment/locale/cs');

import moment from 'moment'

moment.locale('cs');
//const axios = require("axios");

import axios from "axios";

axios.defaults.withCredentials = true;
import VueQrcode from '@chenfengyuan/vue-qrcode';
//import Vue from 'vue'
//Vue.component(VueQrcode.name, VueQrcode);


//import VTooltip from 'v-tooltip'
//Vue.use(VTooltip)

//import "styles/tooltip.scss"

import QrToPdf from "./components/QrToPdf.vue";


import qrcodes from '../utils/qrcodes'

import svg1 from '../utils/svg-tpl';
 // import vmodal from 'vue-js-modal'
 //   Vue.use(vmodal)

// import 'vue-js-modal/dist/styles.css'
import _ from "lodash";

  const ITEMS_COUNT = 27

import { translator } from "@/lib/translator";

export default {
  name: "QRgen",
  components: { Mrizkobunka, QrToPdf},
  computed: {

    me() {

        return {}
    },

    formatedKilowat(){
      var arr =[]

      for(var i in qrcodes.kilowaty){
        var k = qrcodes.kilowaty[i];
        var t

        if ( k<100 ){
          t = `${k*10} W`
        } else{
          t= `${k/100} kW`
        }
        arr.push({text: t, value: `${k}`})
      }

      return arr
    },

    voltySelect(){
      var arr=[]
      for(var i in qrcodes.volty){
        arr.push({text: `${qrcodes.volty[i]}V`, value: qrcodes.volty[i] })
      }
      return arr
    },

    windingProtectionSelect(){
      var arr=[]
      for(var i in qrcodes.windingProtections){
        arr.push({text: `${qrcodes.windingProtections[i]}`, value: qrcodes.windingProtections[i] })
      }
      return arr
    }

  },
  watch:{

    qr: {
      handler(val, oldVal) {

        this.sestav()
      },
      deep: true
    },

    kod(){
      svg1.generuj(this.kod,this.svg_template, (svg)=>{
        this.svgp=svg
      })
    },
    sort_by: 'reloadCodes',
    search: 'searchCodes',
      filter_mount:'searchCodes',
      filter_power:'searchCodes',
      filter_model:'searchCodes',
      filter_producer:'searchCodes',
      filrer_product:'searchCodes',
    svg_template(){
      svg1.generuj(this.kod,this.svg_template, (svg)=>{
        this.svgp=svg
      })
    }
  },
  data(){
    return {
      selected_drawings:[],
      selected_datasheets:[],
      selected_manuals:[],
      searchoptions_datasheets:[],
      searchoptions_manuals:[],
      searchoptions_drawings:[],

      svg_template:2,
      sort_by:'',
      loziska: qrcodes.nucka,
      kod:' ',
      qr:[],
      dest:'',
      codes:[],
      edited_id:null,
      svgp:"",
      kod_for_print:"",
      search:'',

      filrer_product:1,
      filter_producer:'',
      producer_options:[],
      mount_options:  [],
      power_options:  [],
      model_options:   [],
      filter_mount:'',
      filter_power:'',
      filter_model:''
    }
  },
  mounted() {


    this.qr[1]='1111'

    for(var i=0; i< ITEMS_COUNT; i++) this.qr[i]=''
    this.qr[21] = this.me.companyID
    this.sestav()
    this.reloadCodes()
  //  this.loadOptions()

      fetch(
          `/admin/datasheets`
      ).then(res => {
        res.json().then(json => (this.searchoptions = json.items));

      });

  },
  methods:{
    onSearch(search, loading) {
      if(search.length) {
        loading(true);
        this.search2(loading, search, this);
      }
    },

    search2: _.debounce((loading, search, vm) => {
      fetch(
          `/admin/datasheets?search=${encodeURI(search)}`
      ).then(res => {
        res.json().then(json => {
          console.log(';kua', json)
              vm.searchoptions_datasheets = json.items.datasheet
              vm.searchoptions_manuals = json.items.manual
              vm.searchoptions_drawings = json.items.drawing
        }//(vm.searchoptions = json.items)

        );
        loading(false);
      });
    }, 350),



    onMbChange(a,b){
      console.log(' On mb vhange', a, b)
    },

    $t: translator,

    product_from_qr(c){
      return c.params[0]
    },


    pdfPrintDialog(c){
      //this.$modal.show('modal1')
      this.$refs.qrtopdfdialog.showModal()

      if (c) {
        this.kod_for_print = c
      }else{
        this.kod_for_print = { params: this.qr }
      }

      if (this.kod_for_print.last_sn >0){

        var nsn = parseInt(this.kod_for_print.last_sn)
        this.kod_for_print.params[20] = nsn+1
        this.$refs.qrtopdfdialog.sn1 = nsn+1
      }

      this.$refs.qrtopdfdialog.refreshInitial()

    },

    df: function (time, format) {
      if (!time) return '';
      //moment.locale(this.$i18n.locale);
      var a = moment(time);
      return a.format(format ? format : "LLL")
    },

    openForEdit(c){

      this.qr = c.params
      this.edited_id = c.id

      this.selected_datasheets = c.datasheets_da
      this.selected_manuals = c.datasheets_ma
      this.selected_drawings = c.datasheets_dr
    },
    printBtn(c){

      //  this.$router.push({ path: '/qr-direct-print?qr='+c.code})
      window.open('/qr-direct-print?qr='+c.code, '_blank');

    },
    destroyCode(c){

      if (!confirm('Opravdu smazat? ')) {
        return
      }
      axios.delete("/qrcodes/"+c.id).then(result => {
        if (result.status == 200 || result.status == 204) {
          this.$toasted.show( this.$t('qrcode.removed-ok'),{ fullWidth: true,iconPack: 'fontawesome', type: 'success' ,duration : 3000})
          for(var i in this.codes){
            if (c.id == this.codes[i].id){
              this.codes.splice(i,1)
            }
          }
          this.dataReset()
        }
      }).catch(e =>{
        console.error(' notif errrr', e)
        this.$toasted.show( "error "+e.toString(),{ fullWidth: true,iconPack: 'fontawesome', type: 'error' ,duration : 3000})
      })



    },

    searchCodes:_.debounce(function () {
      this.reloadCodes()
    }, 700),

    reloadCodes(){
      const qp={
        mount: this.filter_mount,
        power: this.filter_power,
        model: this.filter_model,
        producer: this.filter_producer,
        product: this.filrer_product
      }

      axios.get("/qrcodes.json",{params: qp}).then(result => {

        console.log('CODES LOADED', result)

        if (result.status == 200 || result.status == 304) {
          this.codes = result.data
        }
      }).catch(e =>{
        console.error(' notif errrr', e)
      })
    },

    createCode(){
      const data={
        "qrcode":{
          "name":"",
          "code": this.kod,
          "params": this.qr
        }
      }
      axios.post("/qrcodes", data).then(result => {
        if (result.status == 200 || result.status == 201) {
          this.codes.push(result.data.qrcode)
          //this.$toasted.show( this.$t('qrcode.created-ok'),{ fullWidth: true,iconPack: 'fontawesome', type: 'success' ,duration : 3000})
          this.dataReset()
        }
      }).catch(e =>{
        console.error(' notif errrr', e)
        this.$toasted.show( "error "+e.toString(),{ fullWidth: true,iconPack: 'fontawesome', type: 'error' ,duration : 3000})
      })
    },

    loadOptions(){
      axios.get('/qrcodes/filter-options').then(resp => {

        this.producer_options = resp.data.producer_options
        this.mount_options = resp.data.mount_options
        this.power_options = resp.data.power_options
        this.model_options = resp.data.model_options


      })
    },

    updateCode(){

      let ids = []
      for(let i in this.selected_datasheets) {
        ids.push(  this.selected_datasheets[i].id  )
      }
      for(let i in this.selected_drawings) {
        ids.push(  this.selected_drawings[i].id  )
      }
      for(let i in this.selected_manuals) {
        ids.push(  this.selected_manuals[i].id  )
      }

      var qr2=[]
      for(var i=0; i<ITEMS_COUNT; i++){
        if (this.qr[i]) qr2[i] = this.qr[i]
        else qr2[i] =''
      }

      const data={
        "qrcode":{
          "name":"",
          "code": this.kod,
          "params": qr2,
          "datasheet_ids": ids,

        }
      }
      axios.put(`/qrcodes/${this.edited_id}`, data).then(result => {
        if (result.status == 200 || result.status == 201) {

          for(var i in this.codes){
            if (this.edited_id == this.codes[i].id){
              this.codes[i] = result.data.qrcode
            }
          }

          //this.$toasted.show( this.$t('qrcode.updated-ok'),{ fullWidth: true,iconPack: 'fontawesome', type: 'success' ,duration : 3000})
          this.dataReset()
        }
      }).catch(e =>{
        console.error(' notif errrr', e)
        this.$toasted.show( "error "+e.toString(),{ fullWidth: true,iconPack: 'fontawesome', type: 'error' ,duration : 3000})
      })

    },
    btnCancel(){

      this.dataReset()

    },
    dataReset(){
      this.edited_id=null
      for(var i=0; i<ITEMS_COUNT; i++) this.qr[i]=''
      this.qr[21] = this.me.companyID
      this.sestav()
    },
    btnSave(){
      if (this.edited_id) {
        this.updateCode()
      }else{
        this.createCode()
      }
    },
    btnSaveAsNew(){
      this.createCode()
    },
    sestav(){
      this.kod = qrcodes.codeAsString(this.qr)
    }
  }

}
</script>

<style scoped lang="scss">

.podpora{

  width:340px;
  padding-top:14px;
  margin-left:10px;
}

.siroky-container{
  margin-top:80px;
}

.kalkulator {
  min-width:  1300px;


  select option{

  }

  border-collapse: collapse;
  td{ border:1px solid #ccc; min-width:22px; width: 22px; text-align: center; overflow:hidden; }
  th{
    border:1px solid #ccc; text-align: center; padding-left: 4px; padding-right: 4px;
  }
  .border-right-b{
    border-right: 1px solid black;
  }
  .pozky td {
    text-align: center;
  }
  td.mrb{
    padding:0;
    text-align: left;
    position: relative;
  }
}

.vysledek{
  letter-spacing: 3px;
  font-size: 10px;

}


i.napoveda{

  border-radius:8px;
  background: #fab20a;
  display: inline-block;
  width:16px;
  height:16px;
  line-height: 16px;
  cursor:help;
}


.selectrow{
  td{
    select{
      width:100%;
      text-align: center;
 display: block;
  font-size: 12px;
      line-height: 14px;

      padding:2px 1px;
      border-width: 1px;
      border-radius: 2px;
      background: white;

 box-sizing: border-box;
  margin: 0;
  //background-color: #fff;

    }
  }
}



.colkal1{


 // width: calc(100% - 160px);


  @media ( max-width: 1492px) {
    max-width: 100%;
  }


}
.colkal2{
  flex-basis: 160px;
  width:160px;
}

@media (max-width: 1284px) {
  .colkal2{
    display: none;
  }


  table.kalkulator{
    th{
      font-size: 80%;
      word-wrap: break-word;

    }
  }


    #seznam{
      td {
        font-size: 90%;
        .linkakod {
          word-wrap: break-word;
          width:335px;
        }
        &.skodem{

        }
      }

    }

}



</style>
