<script>


import cagpdf from "@/entrypoints/components/help/cagpdf.vue";
export default {
   name: "manual",
  components: {cagpdf },
  props:['motor', 'docs'],
  data(){
    return{
      pdf: ''
    }
  },
  created() {

    console.log('D', this.docs.manual[0].file_url)
    this.pdf = this.docs.manual[0].file_url

  },



}



</script>

<template>
  <cagpdf :pdf="pdf"></cagpdf>
</template>

<style scoped>

</style>