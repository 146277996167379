<template>

  <vue-final-modal v-model="showM" classes="modal-container" content-class="modal-content-f w800">
    <div class="modal-header">
      <b>Detail Firmy</b>
      <button type="button" class="close" @click.prevent="hideModal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal__content">
      <div class="row">
        <div class="col-md-6">

          <div class="form-group ">
            <label for="inputCompany" class="">{{ $t('reg.companyname') }}</label>
            <input type="text" v-model="company.name" class="form-control"/>
          </div>

        </div>

        <div class="col-md-6">
          <div class="form-group ">
            <label for="inputCat" class="">{{ $t('reg.company.category') }}</label>
            <select v-model="category" class="form-control">
              <option value="1">{{$t('category.Manufacturer')}}</option>
              <option value="2">{{$t('category.Wholesale')}}</option>
              <option value="3">{{$t('category.Manufacturer-of-unit')}}</option>
              <option value="4">{{$t('category.Machine-user')}}</option>
            </select>
          </div>

        </div>


        <div class="col-md-6">

          <div class="form-group ">
            <label for="inputCountry" class="">{{ $t('reg.stat') }} ...</label>
            <country-select v-model="company.country" :country="company.country" class="form-control countrysel"/>
          </div>
        </div>
        <div class="col-md-6">

          <div class="form-group ">
            <label for="inputAddress" class="">{{ $t('reg.adresa') }}</label>
            <input type="text"           v-model.trim="company.address"             class="form-control form-control"             id="inputAddress"
                :placeholder="$t('reg.adresa')"
            />

          </div>
        </div>
          <div class="col-md-6">

          <div class="form-group ">
            <label for="inputPhone" class="">{{ $t('reg.telefon') }}</label>
            <input type="text"       v-model.trim="company.phone"
                class="form-control form-control"
                :placeholder="$t('reg.telefon')"
            />
          </div>


        </div>
      </div>



    </div>

    <div class="modal__action">
      <button class="btn btn-primary" @click="handleSaveChanges">uložit změny</button>
      <button class="btn btn-outline-dark ml-4" @click="hideModal">zrušit</button>
    </div>
  </vue-final-modal>
</template>

<script>
import modalKkMixin from "@/lib/modal-kk-mixin";

import axios from "axios";

export default {

  mixins: [modalKkMixin],
  name: "modal-company",
  props: ['company'],

  watch: {},
  data() {
    return {
      user: {},
      country:{},

    }
  },
  methods: {


    handleSaveChanges() {

      if (this.company.id>0){

      }else {

        axios.post('/companies', this.company).then(response => {
          this.$emit('companyUpdated')
          this.hideModal()
        })
      }
    },
    handleImageChange(f) {

    }
  }

}
</script>

<style scoped>

</style>