<template>
  <table class="table ">
    <tr>
      <th>ID</th>
      <th>Nazev firmy</th>
      <th>Uživatelé</th>
      <th>Vytvořeno</th>
      <th>Typ</th>
      <th>Poznámka</th>
      <th></th>
      <th></th>
    </tr>
    <tr v-for="a in companies" :key="a.id" :class="a.confirmed_at == null ? 'nepotvrdilemail' :''">
      <td>{{a.id}}</td>
      <td><b>{{ a.name}} </b></td>

      <td>
        <DIV v-for="u in a.users " :key="u.objid">
          {{u.name}}, {{u.address}}, {{u.country}}, {{u.email}}
        </DIV>
      </td>


      <td>{{ a.created_at }}</td>
      <td>{{ $t(a.category_name)}}</td>
      <td></td>
      <td><button class="btn btn-success btn-sm" @click="acceptCompany(a)">approve</button></td>
      <td><button class="btn btn-danger btn-sm" @click="rejectCompany(a)">reject</button></td>

    </tr>
  </TABLE>
</template>

<script>

import {translator} from "@/lib/translator";
export default {
  props:['companies'],
  name: "CompaniesTable",
  methods:{
    $t: translator,

     rejectCompany(a){
       this.$parent.rejectCompany(a)
     },
    acceptCompany(a){
       this.$parent.acceptCompany(a)
    }
  }

}
</script>

<style scoped>

</style>
