<script>
import MotorAttributes from "@/entrypoints/admin/MotorAttributes.vue";

export default {
  name: "plate",
  components: {MotorAttributes},
  props:['motor']
}
</script>

<template>
  <h2>digitalni stitek</h2>
  <motor-attributes :motor="motor"></motor-attributes>


</template>

<style lang="scss">



.attributes{
  border: 1px solid green;
  border-radius: 20px;
  .row{
    border-bottom: 1px solid #333;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

</style>