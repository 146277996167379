<template>
  <div>
    <div id="team-list">
      <div>
<!--
        <div class="options w-100 py-2">
          <div class="d-inline-block text-center">
            <router-link :to="'/main/motors/'">
              <div class="icon mx-2">
                <span class="ukazatel-alertu" v-if="pocetVystrah>0">{{pocetVystrah}}</span>
                <div class="active-line"></div>
                <img :src="`/img/moje_karty.png`" alt />
              </div>
              <div class="mt-1">{{$t('option.moje-karty')}}</div>
            </router-link>
          </div>

          <div class="d-inline-block text-center">
            <router-link to="/groups" class="router-link-exact-active active-line">
              <div class="icon mx-2">

                <div class="active-line"></div>
                <img :src="`/img/group-50.png`" alt />
              </div>
              <div class="mt-1">{{$t('menu.team')}}</div>
            </router-link>
          </div>
        </div>
-->


<!--
        <div class="mt-2 mx-2">
          {{ $t('reg.companyname') }} {{members.length}} {{ $t('team.clenu') }}
        </div>
        <h3 class="mt-1 ml-2"> {{company.name}}</h3>

        <div class="options w-100 py-2" >

        </div>

        <div class="d-flex pt-2  submenu2">
          <a @click.prevent="tab=4; load_teammotors()" href="#" :class="'mx-2 '+(tab==4 ? 'active':'')">
            {{$t('groups.subnemu.prehled')}}
          </a>
          <a @click.prevent="tab=1" href="#" :class="'mx-2 '+(tab==1 ? 'active':'')">
            {{$t('option.clenove-teamu')}}
          </a>
        </div>-->
      </div>


      <!-------------------------------------------------------------------------------------------------------------->
      <div class="list w-100 py-2 px-1 mt-3" v-if="tab==1">

        <!-- <h4 class="h6 pt-1">{{$t('team.members')}}</h4>-->
        <TeamMember v-for="mm in members" :key="mm.id" :m="mm" :my_role="my_role" :company="company.name" :account_id="null" @click="handleMemberClicked(mm)"></TeamMember>
<hr>
        <button @click.prevent="handleAddMember">Pridat clena</button>
      </div>

      <div v-if="tab==4 " id="motor-list">

        <div class="list  w-100 py-2 px-1 mt-3">
          <!--          <h4 class="h6 pt-1">{{$t('team.motors-head')}}</h4>
                    -->
          <MotorItem v-for="motor in motors" :key="motor.id" :motor="motor"></MotorItem>

        </div>
      </div>


    </div>



  </div>

  <ModalKkUser :user="current_user" @userUpdated="handleUserUpdated" ref="userModal"></ModalKkUser>
</template>

<script>
import axios from 'axios'
import moment from "moment";


moment.locale('cs');
import TeamMember from "./TeamMember.vue";

import {translator} from "@/lib/translator";
import ModalKkUser from "@/entrypoints/components/company/ModalUser.vue";
export default {
  name: "Company1",
  components:{ModalKkUser, TeamMember },
  data(){
    return {
      current_user:{},
      pocetVystrah:0,
      members: [],
      company: {name:'#'},
      tab: 1,
      invitations: [],
      motors: [],
      invResultClass: '',
      my_role: 0,
    }
  }, mounted(){
    this.loadTeam()
  },
  methods: {

    handleUserUpdated(){
      this.loadTeam()

    },
    $t: translator,
    df: function (time, format) {
      moment.locale(this.$i18n.locale);
      var a = moment(time);

      return a.format(format ? format : "LLL")
    },

    loadTeam(){
        axios.get("/company/members.json?t="+new Date().getTime()).then(result => {
          if (result.status==200){
            this.members = result.data.members
            this.company = result.data.company

            for(var i=0; i< this.members.length; i++) {
              this.members[i].visiblesel=false
            }
            this.load_teammotors()
          }
        }).catch(err =>{

            if (err.response.status == 401){
              window.location.href = '/users/sign_in'
            }

          console.log('err', err.response.status)
        }
        )
    },
    load_teammotors() {
      /*
        axios.get(`/company/motors?t=${new Date().getTime()}`).then(result => {
          //console.log('res', result.data)
          if (result.status == 200 || result.status == 304) {
            this.motors = result.data.motors
          }
        })*/
    },

    handleAddMember(){
      this.current_user={}
      this.$refs.userModal.showModal()
    },

    handleMemberClicked(mm){

      this.current_user = mm
      this.$refs.userModal.showModal()
    },
  }
}
</script>

<style scoped>


</style>
