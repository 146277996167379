<template>
  <div id="transl-app" class="container-fluid">
    <div class="row py-2">

        <div class="my-2 col-md-4 d-flex">
          <div class="form-inline">
            <label>Kategorie:</label>
          </div>
          <select class="form-control" v-model="katsel">
            <option value="">all</option>
            <option value="motor">web / Engine</option>
            <option value="motor.list">web / Engine list</option>
            <option value="tooltip">web / Tooltip (help)</option>

            <option value="m">mobile / all</option>
            <option value="m.QRreader">mobil / QR reader</option>
            <option value="m.PViewEngine">mobil / Engine card</option>
            <option value="m.PEngineList">mobil / Engine list</option>
            <option value="m.PRegistration">mobil / registration</option>
            <option value="m.CVCauseSolution">mobil / Cause - Solution</option>
            <option value="m.CVSheet">mobil / motor Sheet</option>
            <option value="m.PCommissioning">mobil / motor / Commissioning</option>
            <option value="m.PMaintance">mobil / motor/ Maintance</option>
            <option value="m.PLubrication">mobil / motor / Lubrication</option>
            <option value="m.PCreateEditEngine">mobil / motor / Create, Edit</option>
            <option value="m.PMeasurement">mobil / motor / Measurement</option>

            <option value="m.PInstallation">mobil / motor / Installation</option>
            <option value="m.PDefect">mobil / motor / Defects</option>
          </select>

      </div>


        <div class="my-2 col-md-5 d-flex">
          <div class="form-inline">
            <label>Hledat:</label>
          </div>
          <input type="text" class="form-control input-sm" v-model="hledani" />
<!--          <button @click="hledat()" class="btn btn-secondary">Hledat</button>
          <button @click="hledani='';hledat()" class="btn btn-outline-black">Zruš</button>
    -->
        </div>

      <div class="ml-auto pt-2">
          <button @click="pridavani = !pridavani" class="ml-4 btn btn-info">Přidat</button>
      </div>
    </div>
    <div class="panel-new" v-if="pridavani">
      <div class="row">
        <div class="col-sm-2">
          <div class="form-group">
            <label>Key</label>
            <input v-model="new_key" class="form-control" />
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group">
            <label>CS</label>
            <textarea v-model="new_cs" class="form-control" />
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group">
            <label>EN</label>
            <textarea v-model="new_en" class="form-control" />
          </div>
        </div>

      </div>
       <div class="row">
        <div class="col-sm-2">&nbsp;</div>
        <div class="col-sm-4">
          <div class="form-group">
            <label>RU</label>
            <textarea v-model="new_ru" class="form-control" />
          </div>
        </div>

        <div class="col-sm-4">
          <div class="form-group">
            <label>CN</label>
            <textarea v-model="new_zh" class="form-control" />
          </div>
        </div>




        <div class="col-sm-1">
          <div class="form-group mt-4">

            <button class="btn btn-success" @click="insertTrans()">Přidat</button>

            <button @click="pridavani = !pridavani" class="btn btn-secondary mt-1">Zrušit</button>
          </div>
        </div>
      </div>
    </div>

    <table class="table table-striped">
      <tr>
        <th>ID</th>
        <th>Key</th>
        <th>
          <select v-model="lang1">
            <option value="cs">CS</option>
            <option value="en">EN</option>
            <option value="ru">RU</option>
            <option value="zh">zh_CN</option>
          </select>
        </th>
        <th>
          <select v-model="lang2">
            <option value="cs">CS</option>
            <option value="en">EN</option>
            <option value="ru">RU</option>
            <option value="zh">zh_CN</option>
          </select>
        </th>
      </tr>
      <tr v-for="(tr) in nalezeno" v-bind:key="tr.id">
        <td class="trans-col1">{{tr.id}}</td>
        <td class="trans-col1" style="word-wrap: break-word; max-width:250px;">{{tr.key}}</td>
        <td>
          <div class="d-flex">
            <input
              type="text"
              v-model="tr[lang1]"
              class="form-control"
              @keyup="changeTrans(lang1,tr)"
            />
            <button
              @click="updateTrans(lang1,tr)"
              v-if="modified(tr.key,lang1)"
              class="btn btn-outline-success"
            >OK</button>
          </div>
        </td>
        <td>
          <div class="d-flex">
            <input
              type="text"
              v-model="tr[lang2]"
              class="form-control"
              @keyup="changeTrans(lang2,tr)"
            />
            <button
              @click="updateTrans(lang2,tr)"
              v-if="modified(tr.key,lang2)"
              class="btn btn-outline-success"
            >OK</button>
          </div>
        </td>
        <!-- <td>
                   <div class="d-flex"><input type="text" v-model="tr.cn" class="form-control" @keypress="changeTrans('cn',tr)"  /><button @click="updateTrans('cn',tr)" v-if="modified(tr.key,'cn')" class="btn btn-outline-success" >OK</button></div></td>
        -->
      </tr>
    </table>
  </div>
</template>

<script>
//  trans =[{"id":2766,"key":"Administrace","cs":"Administrace","en":"Administration2","zh":"行政"},{"id":48,"key":"Back","cs":"Zpět","en":"Back","zh":"后面"},

import  axios  from "axios";

axios.defaults.withCredentials = true;
export default {
  name: "Translate",

  data() {
    return {
      trans: [],
      ismodified: [],
      hledani: "",
      lang1: "cs",
      lang2: "en",
      new_key:'',
      new_cs:'',
      new_en:'',
      new_ru:'',
      new_cn:'',
      new_zh:'',
      pridavani: false,
      katsel:''
    };
  },

  watch: {

    katsel(v){
      console.log('change katsel', v)

    }

  },
  computed:{

    nalezeno(){
      if (this.hledani == '' && this.katsel=='')
        return this.trans
      else
        return this.hledat()
    }


  },



  methods: {
    hledat: function() {
      var arr = [];
      //var k = 0;

      var thisRegex = new RegExp(".*" + this.hledani.toLowerCase() + ".*");

      var katRegex = new RegExp( '^'+this.katsel + "\\..*");

      for (var i = 0; i < this.trans.length; i++) {
        var tr = this.trans[i];
        if (
                (thisRegex.test(tr.cs.toLowerCase()) || thisRegex.test(tr.key.toLowerCase()))
                && (this.katsel=='' || katRegex.test(tr.key) )

        ) {
          //this.$set(this.trans, k, this.trans[i]);

          arr.push(tr)

        }
      }
      return arr
    },

    modified: function(key, loc) {
      return this.ismodified[loc + "." + key];
    },

    changeTrans: function(loc, tr) {
      this.ismodified[loc + "." + tr.key] = true;
    },

    insertTrans_post(locale, key, value, cb){
        axios
        .post(process.env.VUE_APP_API_ROOT_URL + "/translate/keyupdate", {
          locale: locale,          key: key,          value: value
        })
        .then(response => {
          if (response.data.id>0){
            let found=false
            for(let i=0;  i< this.trans.length; i++){
              let element = this.trans[i];
              if (element.key == key){
                console.log('nasel', element)
                found=true
                this.updateTranslate(this.trans[i], locale, value)

              }
            }
            if (!found){
              console.log('udelat')
              let newitem={id: response.data.id, key: response.data.key}
              newitem[locale] = value
              this.trans.push(newitem)
            }
            //eval(cb)
            if (typeof cb == 'function' ) cb()
          }
        });
    },

    updateTranslate(tr, locale, value ){
      console.log("UTR", tr, locale,value)

    },


    insertTrans(){
      this.insertTrans_post('cs', this.new_key, this.new_cs,
          this.insertTrans_post('en', this.new_key, this.new_en,
              this.insertTrans_post('ru', this.new_key, this.new_ru,
                  this.insertTrans_post('zh', this.new_key, this.new_zh)))  )

      this.new_zh=''
      this.new_ru=''
      this.new_cs=''
      this.new_en=''
      this.new_key=''
    },

    updateTrans: function(locale, tr) {
      console.log("UPdatePOst TR")
      axios
        .post( "/admin/translate/keyupdate", {
          locale: locale,
          key: tr.key,
          value: tr[locale]
        })
        .then(response => {
          console.log("tr", response.data);
          //this.$set(this.ismodified, locale + "." + tr.key, false);
          this.ismodified[locale + "." + tr.key] = false

          this.$forceUpdate();
        });
    }
  },
  mounted() {
    axios
            .get("/admin/translate/index?"+(new Date().getTime()))
            .then(response => {
              const najdiTran = function(key, locale) {
                for (let i = 0; i < response.data.length; i++) {
                  let d = response.data[i];
                  if (d.key == key && d.locale == locale) return d.value;
                }
              };

              this.trans = [];
              for (let i = 0; i < response.data.length; i++) {
                let d = response.data[i];
                if (d.locale == "cs") {
                  var tran = { id: d.id, key: d.key, cs: d.value };
                  tran.en = najdiTran(d.key, "en");
                  tran.zh = najdiTran(d.key, "zh");
                  tran.ru = najdiTran(d.key, "ru");
                  this.trans.push(tran);
                }
              }
            });
  }
};
</script>

<style scoped>
.panel-new {
  padding: 10px;
  background: #dddddd;
  border: 1px solid #aaa;
}


</style>
