// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
import Companies from "@/entrypoints/admin/Companies.vue";

console.log('Vite ⚡️ Rails ...3..')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'
/*
import '~/stylesheets/main.scss'
import '~/stylesheets/small.scss'
import '~/stylesheets/tooltip.scss'
*/

//import 'bootstrap/scss/bootstrap.scss'
import '../lib/locale_loader'
import {Tooltip} from 'floating-vue'

import FloatingVue from 'floating-vue'

//import 'floating-vue/dist/style.css'

import vueCountryRegionSelect from 'vue3-country-region-select'
import Translate from "./admin/Translate.vue"

import App2 from "~/entrypoints/QRgen.vue";

import { createApp } from 'vue'
import axios from "axios";

import {localesLoader} from "~/lib/locale_loader";
import Company from "@/entrypoints/components/company/Company.vue";
import Users from "@/entrypoints/admin/Users.vue";
import Datasheets from "@/entrypoints/admin/datasheets/Datasheets.vue";

import Registrace from "@/entrypoints/Registrace.vue";
import Manufacture from "@/entrypoints/admin/Manufacture.vue";
const csfr_token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
axios.defaults.headers.common['X-CSRF-TOKEN'] = csfr_token;
axios.defaults.withCredentials=true

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import MainHelp from "@/entrypoints/components/help/main-help.vue";

const onALoad = function(){


    const app = createApp(App2)
    app.use( FloatingVue)
    app.component("v-select", vSelect)
    app.mount('#app-qr-gen')
    createApp(Translate).mount('#app-admin-translator')

    const app_companies = createApp(Companies)
    app_companies.use(vueCountryRegionSelect)
    app_companies.mount('#app-beedroid-companies')

    const appc = createApp(Company)
    appc.use(vueCountryRegionSelect)
    appc.mount( '#app-beedroid-company')



    createApp(Users).mount('#app-beedroid-admin-users')

    createApp(Datasheets).mount('#app-admin-products')

    const app_reg=createApp(Registrace)
    app_reg.use(vueCountryRegionSelect)
    app_reg.mount('#app-registrace')

    createApp(Manufacture).mount('#app-manufacture')


    const help_el = document.getElementById('app-help')
    const aphelp= createApp(MainHelp, {mm: help_el.dataset.mm})
    aphelp.mount(help_el)
}


localesLoader(onALoad)

