<template>

  <vue-final-modal v-model="showM" classes="modal-container" content-class="modal-content-f w800">
    <div class="modal-header">
      <b>Datasheet</b>
      <button type="button" class="close" @click.prevent="hideModal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal__content">
      <div class="row">
        <div class="col-md-6">

          <div class="form-group ">
            <label for="inputCompany" class="">{{ $t('name') }}</label>
            <input type="text" v-model="ds.name" class="form-control"/>
          </div>

          <div class="form-group ">
            <label for="inputCompany" class="">Typ dokumentu</label>
            <select v-model="ds.kind" class="form-control">
                <option value="datasheet">datasheet</option>
                <option value="drawing">výkres</option>
                <option value="manual">manual</option>
            </select>
          </div>


          <div class="form-group ">
            <label>Soubor s datasheetem</label>
            <input type="file" class="form-control" id="file_f1"/>
          </div>


        </div>
      </div>
    </div>

    <div class="modal__action">
      <button class="btn btn-primary" @click="handleSaveChanges">uložit změny</button>
      <button class="btn btn-outline-dark ml-4" @click="hideModal">zrušit</button>
    </div>
  </vue-final-modal>
</template>

<script>
import modalKkMixin from "@/lib/modal-kk-mixin";

import axios from "axios";

export default {

  mixins: [modalKkMixin],
  name: "modal-product",
  props: ['ds'],

  watch: {},
  data() {
    return {
      user: {},
      country:{},
      company:{}

    }
  },
  methods: {


    handleSaveChanges() {

      const formData = new FormData()
      formData.append('name', this.ds.name)
      let f1 = document.getElementById('file_f1').files[0]
      if (f1) formData.append("file", f1, f1.name)

      formData.append("kind", this.ds.kind)

      if (this.ds.id>0){
        axios.put(`/admin/datasheets/${this.ds.id}`, formData).then(response => {
          this.$emit('updated', this.ds.kind)
          this.hideModal()
        })
      }else {
        axios.post('/admin/datasheets', formData).then(response => {
          this.$emit('updated', this.ds.kind)
          this.hideModal()
        })
      }
    },
    handleImageChange(f) {

    }
  }

}
</script>

<style scoped>

</style>